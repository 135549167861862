import './App.css';
import PostList from './Components/PostList';
import NewComponents from './Components/new_components';

function App() {
  return (
    <div>
      <h1>Stefanus Cahya Abadi</h1>
      <PostList/>
      <NewComponents/>
    </div>
  );
}

export default App;
